<template>
  <div class="page">
    <img src="./img/bannerDetailInfo.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  background-color: #f5b039;
  img {
    width: 100%;
  }
}
</style>